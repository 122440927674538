import * as React from 'react';
import { SVGProps } from 'react';

const SvgGaIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.881 10.061H8.643l-3.268-5.66a1.938 1.938 0 1 1 3.358-1.937L12 8.122l-1.119 1.94Z"
        fill="#FCBC05"
      />
      <path
        d="M17.916 22.247a1.94 1.94 0 0 1-2.65-.71L12 15.878l-3.267 5.659a1.939 1.939 0 1 1-3.357-1.94l3.267-5.658h6.716l3.267 5.659a1.94 1.94 0 0 1-.71 2.649Z"
        fill="#E94335"
      />
      <path
        d="M23.831 12a1.94 1.94 0 0 1-1.939 1.939h-6.534l-1.12-1.94-1.118-1.938L12 8.123l3.267-5.659a1.939 1.939 0 0 1 3.359 1.938l-3.268 5.66h6.534c1.071 0 1.94.868 1.94 1.938Z"
        fill="#1973E8"
      />
      <path
        d="M10.88 10.063 9.762 12 8.642 13.94H2.107a1.939 1.939 0 0 1 0-3.877h8.774Z"
        fill="#34A853"
      />
      <path
        d="M15.358 13.94H8.642L9.761 12l1.12-1.938L12 8.123l1.12 1.94L14.238 12l1.12 1.94Z"
        fill="#174FA7"
      />
    </svg>
  );
};
export default SvgGaIcon;
