import * as React from 'react';
import { SVGProps } from 'react';

const SvgFortuneWheel = (props: SVGProps) => {
  return (
    <svg
      width={142}
      height={75}
      viewBox="0 0 142 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M71 133.818c37.003 0 67-29.956 67-66.909S108.003 0 71 0 4 29.956 4 66.91c0 36.952 29.997 66.908 67 66.908Z"
          fill="#FFD04E"
        />
        <path
          d="M71 129.804c33.673 0 60.97-27.26 60.97-60.887 0-33.628-27.297-60.888-60.97-60.888-33.672 0-60.97 27.26-60.97 60.888 0 33.627 27.297 60.887 60.97 60.887Z"
          fill="#C59D31"
        />
        <path
          d="M71 127.128c33.303 0 60.3-26.961 60.3-60.218C131.3 33.652 104.303 6.69 71 6.69S10.7 33.652 10.7 66.91c0 33.257 26.997 60.218 60.3 60.218Z"
          fill="#FFFFF5"
        />
        <path
          d="m71 66.91-39.798 39.743c-4.824-4.817-8.576-10.17-11.256-15.924L71 66.909Z"
          fill="#F3F3F3"
        />
        <path
          d="M47.148 117.894c6.03 2.81 12.462 4.416 18.894 5.085L71 66.909l-23.852 50.985ZM85.606 121.239c6.299-1.605 12.195-4.416 17.688-8.296L71.001 66.909l14.605 54.33Z"
          fill="#F3F3F3"
        />
        <path
          d="M38.706 20.876c-2.68 1.873-5.226 3.88-7.504 6.29a54.937 54.937 0 0 0-6.298 7.493L71 66.91 38.706 20.876Z"
          fill="#F3F3F3"
        />
        <path
          d="M16.596 52.323c-1.742 6.29-2.278 12.98-1.742 19.404L71 66.909 16.596 52.323ZM75.958 10.973c-6.566-.535-13.132 0-19.43 1.74L71 66.909l4.958-55.936ZM110.798 27.166c-4.824-4.818-10.184-8.565-15.945-11.241L71 66.909l39.797-39.744ZM117.096 99.16c3.886-5.487 6.566-11.509 8.308-17.665L71.001 66.91l46.095 32.25ZM127.012 61.958c-.536-6.423-2.278-12.846-5.092-18.868L71 66.91l56.012-4.952Z"
          fill="#F3F3F3"
        />
        <path
          d="m71 66.91-23.852 50.984c-5.762-2.676-11.256-6.423-15.946-11.241L71 66.909Z"
          fill="#FD9A07"
        />
        <path
          d="M66.043 122.845c6.566.535 13.131 0 19.43-1.739L71 66.909l-4.959 55.936ZM14.988 71.86c.536 6.424 2.278 12.847 5.092 18.869L71 66.909 14.988 71.86Z"
          fill="#FD9A07"
        />
        <path
          d="M24.904 34.66c-3.886 5.486-6.566 11.508-8.308 17.663L71 66.91 24.904 34.66ZM94.853 15.924c-6.03-2.81-12.462-4.416-18.894-5.085L71 66.909l23.853-50.985ZM71 66.91 56.394 12.58c-6.298 1.605-12.194 4.415-17.688 8.296L71 66.909ZM103.294 112.943c2.68-1.874 5.226-3.881 7.504-6.29a54.935 54.935 0 0 0 6.298-7.494L71 66.91l32.294 46.034ZM125.404 81.495c1.742-6.289 2.278-12.98 1.742-19.403l-56.145 4.817 54.403 14.586ZM122.054 43.09c-2.68-5.755-6.432-11.241-11.256-15.925L71.001 66.909l51.053-23.82Z"
          fill="#FD9A07"
        />
        <path
          opacity={0.5}
          d="M127.28 66.909c0-31.046-25.192-56.204-56.28-56.204S14.72 35.863 14.72 66.91c0 10.705 2.948 20.742 8.174 29.172a54.866 54.866 0 0 1-6.834-26.496C16.06 39.342 40.716 14.72 71 14.72s54.94 24.622 54.94 54.865a54.866 54.866 0 0 1-6.834 26.496c5.226-8.43 8.174-18.467 8.174-29.172Zm-42.21 1.338c0 7.761-6.298 14.05-14.07 14.05s-14.07-6.289-14.07-14.05c0-7.761 6.298-14.051 14.07-14.051s14.07 6.29 14.07 14.05Z"
          fill="#8C8C8C"
        />
        <path
          d="m83.462 61.957-10.05-25.56c-.402-.936-1.34-1.739-2.546-1.739-1.206 0-2.144.67-2.546 1.74l-10.05 25.56c-.536 1.471-.938 3.21-.938 4.817 0 7.36 6.03 13.381 13.4 13.381s13.4-6.021 13.4-13.381c.268-1.606 0-3.346-.67-4.818Z"
          fill="#FFFFF5"
        />
        <path
          d="M71 77.48c-5.896 0-10.72-4.818-10.72-10.706 0-1.338.268-2.676.67-3.88L71 37.333l10.05 25.56c.536 1.204.67 2.542.67 3.88 0 6.022-4.824 10.706-10.72 10.706Z"
          fill="#FC680A"
        />
        <path
          d="M71 72.127c2.96 0 5.36-2.396 5.36-5.352 0-2.957-2.4-5.353-5.36-5.353a5.356 5.356 0 0 0-5.36 5.353c0 2.956 2.4 5.352 5.36 5.352Z"
          fill="#FEBA09"
        />
        <path
          d="M71 68.782c1.11 0 2.01-.899 2.01-2.007 0-1.109-.9-2.007-2.01-2.007-1.11 0-2.01.898-2.01 2.007 0 1.108.9 2.007 2.01 2.007Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h142v86H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgFortuneWheel;
