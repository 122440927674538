import * as React from 'react';
import { SVGProps } from 'react';

const SvgFactor3X = (props: SVGProps) => {
  return (
    <svg
      width={34}
      height={35}
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={17} cy={17.91} r={16.5} fill="#F59E0B" stroke="#F59E0B" />
      <path
        d="m12.487 18.054 1.664-2.56h2.464l-2.88 4.048c.64.8 1.222 1.579 1.744 2.336.523.757.94 1.435 1.248 2.032h-2.56c-.085-.16-.192-.357-.32-.592a11.183 11.183 0 0 0-.432-.752 12.3 12.3 0 0 0-.512-.784 8.38 8.38 0 0 0-.512-.72c-.16.203-.325.427-.496.672-.16.245-.314.496-.464.752l-.448.736c-.138.245-.26.475-.368.688H8.183c.128-.267.304-.581.528-.944a29.113 29.113 0 0 1 1.552-2.288c.288-.395.571-.763.848-1.104-.48-.672-.96-1.35-1.44-2.032-.48-.693-.954-1.376-1.424-2.048h2.576l1.664 2.56Zm8.044 6.096c-.288 0-.592-.021-.912-.064-.32-.032-.63-.08-.928-.144-.299-.064-.57-.133-.816-.208a4.71 4.71 0 0 1-.576-.208l.464-1.984c.277.117.63.245 1.056.384.437.128.976.192 1.616.192.736 0 1.274-.139 1.616-.416.341-.277.512-.65.512-1.12 0-.288-.064-.528-.192-.72a1.263 1.263 0 0 0-.496-.48 2.04 2.04 0 0 0-.768-.256 5.1 5.1 0 0 0-.928-.08h-.928v-1.92h1.056c.235 0 .459-.021.672-.064.224-.043.421-.112.592-.208.17-.107.304-.245.4-.416.107-.181.16-.405.16-.672a1.046 1.046 0 0 0-.464-.896 1.235 1.235 0 0 0-.464-.208 1.761 1.761 0 0 0-.528-.08c-.459 0-.886.07-1.28.208-.384.139-.736.31-1.056.512l-.848-1.744c.17-.107.368-.219.592-.336.235-.117.49-.224.768-.32.277-.096.57-.176.88-.24.32-.064.656-.096 1.008-.096.65 0 1.21.08 1.68.24.48.15.875.368 1.184.656.31.277.538.608.688.992.15.373.224.784.224 1.232 0 .437-.123.864-.368 1.28a2.443 2.443 0 0 1-.992.928c.576.235 1.018.587 1.328 1.056.32.459.48 1.013.48 1.664 0 .512-.085.987-.256 1.424-.17.427-.438.8-.8 1.12-.363.31-.827.555-1.392.736-.555.17-1.216.256-1.984.256Z"
        fill="#2D3344"
      />
    </svg>
  );
};
export default SvgFactor3X;
