import * as React from 'react';
import { SVGProps } from 'react';

const SvgScheduleIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.355 10.09c0 .289-.058.565-.175.83a3.958 3.958 0 0 1-.453.773 6.626 6.626 0 0 1-.627.705c-.232.227-.46.443-.685.648-.116.106-.244.227-.383.363-.132.129-.26.261-.384.398-.124.136-.236.265-.337.386a.92.92 0 0 0-.174.284H5.6v1.42H.232a1.793 1.793 0 0 1-.023-.318v-.272c0-.364.058-.697.174-1 .124-.303.283-.584.477-.841a5.4 5.4 0 0 1 .65-.739c.248-.227.492-.454.732-.681l.522-.49c.163-.158.306-.31.43-.454.124-.151.22-.299.29-.443a1.08 1.08 0 0 0 .105-.455c0-.333-.097-.568-.29-.704-.194-.136-.434-.205-.72-.205-.21 0-.407.035-.593.103-.178.06-.344.136-.5.227-.146.083-.274.17-.383.261-.108.084-.19.152-.243.205L0 8.909c.34-.31.736-.564 1.185-.761a3.54 3.54 0 0 1 1.463-.307c.473 0 .88.053 1.22.159.34.106.62.258.836.455.225.189.387.424.488.704.108.273.163.583.163.932ZM9.9 10.09c-.302.387-.623.808-.964 1.262-.34.455-.627.92-.86 1.398H9.9v-2.66Zm1.696-2.067v4.727h.86v1.386h-.86v1.762H9.9v-1.762H6.427v-1.238c.17-.334.384-.709.64-1.125A21.579 21.579 0 0 1 8.912 9.17c.349-.424.697-.806 1.045-1.147h1.638ZM14.284 18h-1.778L16.41 7h1.754l-3.88 11ZM19.517 15.898c.046-.553.143-1.13.29-1.727.147-.599.325-1.186.534-1.762a15.04 15.04 0 0 1 .72-1.625c.264-.515.53-.958.802-1.33h-3.496V8.012H24V9.24a7.906 7.906 0 0 0-.825 1.102c-.294.47-.577 1.004-.848 1.602-.263.591-.495 1.228-.697 1.91a9.95 9.95 0 0 0-.36 2.045h-1.753Z"
        fill="#8B44E5"
      />
    </svg>
  );
};
export default SvgScheduleIcon;
