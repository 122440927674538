import * as React from 'react';
import { SVGProps } from 'react';

const SvgApiNewIcon = (props: SVGProps) => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 16a7.985 7.985 0 0 0 3 6.246v2.416A9.995 9.995 0 0 1 6 16h2Zm8-10c5.185 0 9.449 3.947 9.95 9h-2.012a8.001 8.001 0 0 0-14.553-3.5H12v2H6v-6h2V10a9.98 9.98 0 0 1 8-4Z"
        fill="#fff"
      />
      <g clipPath="url(#a)">
        <path
          d="M15.111 15.651a5.829 5.829 0 0 1 2.067-1.194 2.329 2.329 0 0 0 2.833.646 2.33 2.33 0 0 0 .811-.646 5.829 5.829 0 0 1 2.067 1.194 2.33 2.33 0 0 0 .857 2.776c.29.197.62.327.965.38a5.857 5.857 0 0 1 0 2.385 2.331 2.331 0 0 0-1.822 3.156 5.827 5.827 0 0 1-2.067 1.194 2.329 2.329 0 0 0-2.833-.645 2.327 2.327 0 0 0-.811.646 5.826 5.826 0 0 1-2.067-1.194 2.33 2.33 0 0 0-1.822-3.155 5.856 5.856 0 0 1 0-2.387 2.327 2.327 0 0 0 1.67-1.14 2.328 2.328 0 0 0 .152-2.016Zm4.764 5.864a1.75 1.75 0 1 0-1.73-3.044 1.75 1.75 0 0 0 1.73 3.044Z"
          fill="#fff"
        />
      </g>
      <g clipPath="url(#b)">
        <circle cx={8} cy={8.889} r={6.222} fill="#fff" />
        <path
          d="m15.937 7.766-.946-1.64V4.235a.469.469 0 0 0-.235-.406l-1.639-.946-.946-1.639a.469.469 0 0 0-.406-.234H9.873L8.234.063a.469.469 0 0 0-.469 0l-1.639.946H4.234a.469.469 0 0 0-.406.234l-.946 1.64-1.639.945a.469.469 0 0 0-.234.406v1.893L.063 7.766a.469.469 0 0 0 0 .468l.946 1.64v1.892c0 .167.089.322.234.406l1.639.946.946 1.639a.469.469 0 0 0 .406.234h1.892l1.64.946a.468.468 0 0 0 .468 0l1.64-.946h1.891c.168 0 .323-.09.406-.234l.947-1.64 1.638-.945a.468.468 0 0 0 .235-.406V9.873l.946-1.639a.469.469 0 0 0 0-.468Zm-10.23 1.74a.469.469 0 0 1-.859.26l-1.15-1.724v1.464a.469.469 0 0 1-.937 0V6.494a.469.469 0 0 1 .859-.26l1.15 1.724V6.494a.469.469 0 0 1 .937 0v3.012ZM7.75 7.531a.469.469 0 0 1 0 .938h-.536v.569h1.038a.469.469 0 0 1 0 .937H6.745a.469.469 0 0 1-.47-.469V6.494c0-.26.21-.469.47-.469H8.25a.469.469 0 0 1 0 .938H7.213v.568h.536Zm5.726-.924-.753 3.013a.469.469 0 0 1-.9.035l-.559-1.679-.56 1.679a.47.47 0 0 1-.899-.035l-.753-3.013a.469.469 0 1 1 .91-.227l.362 1.45.495-1.484a.469.469 0 0 1 .89 0l.495 1.485.363-1.45a.469.469 0 0 1 .91.226Z"
          fill="#FF4E4E"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(12 13)" d="M0 0h14v14H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgApiNewIcon;
