import * as React from 'react';
import { SVGProps } from 'react';

const SvgSwapIcon = (props: SVGProps) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="svg-fill"
        d="M16 29.334c-7.364 0-13.333-5.97-13.333-13.334S8.637 2.667 16 2.667c7.364 0 13.334 5.97 13.334 13.333 0 7.364-5.97 13.334-13.334 13.334Zm0-2.667a10.667 10.667 0 1 0 0-21.333 10.667 10.667 0 0 0 0 21.333ZM9.334 12l4-4.666 4 4.666h-2.667v5.334H12V12H9.334Zm13.333 8-4 4.667-4-4.667h2.667v-5.333H20V20h2.667Z"
        fill="#94A3B8"
      />
    </svg>
  );
};
export default SvgSwapIcon;
