import * as React from 'react';
import { SVGProps } from 'react';

const SvgExchangeIcon = (props: SVGProps) => {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="#C5C6C7"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.5 22c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 12.5 20Zm-5-7h9v2h-4v3l-5-5Zm5-4V6l5 5h-9V9h4Z" />
    </svg>
  );
};
export default SvgExchangeIcon;
