import * as React from 'react';
import { SVGProps } from 'react';

const SvgCrossArrowsIcon = (props: SVGProps) => {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={50} height={50} rx={25} fill="#727176" />
      <path
        d="M35.91 18.833H16.724l3.592-3.841a1.225 1.225 0 0 0 0-1.65 1.041 1.041 0 0 0-1.543 0l-5.455 5.833a1.225 1.225 0 0 0 0 1.65l5.455 5.833c.213.228.492.342.771.342.28 0 .559-.114.772-.342a1.224 1.224 0 0 0 0-1.65l-3.592-3.841h19.184c.603 0 1.091-.523 1.091-1.167s-.488-1.167-1.09-1.167Z"
        fill="#fff"
      />
      <path
        d="M31.226 23.342a1.041 1.041 0 0 0-1.543 0 1.225 1.225 0 0 0 0 1.65l3.592 3.841H14.091c-.603 0-1.091.523-1.091 1.167s.488 1.167 1.09 1.167h19.185l-3.592 3.841a1.225 1.225 0 0 0 0 1.65c.213.228.492.342.772.342.279 0 .558-.114.77-.342l5.456-5.833a1.225 1.225 0 0 0 0-1.65l-5.455-5.833Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgCrossArrowsIcon;
