import * as React from 'react';
import { SVGProps } from 'react';

const SvgPassportIcon = (props: SVGProps) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M16.667 1.667a.833.833 0 0 1 .833.833v15a.833.833 0 0 1-.833.833H3.333A.833.833 0 0 1 2.5 17.5v-15a.833.833 0 0 1 .833-.833h13.334Zm-.834 1.666H4.167v13.334h11.666V3.332Zm-2.5 10V15H6.667v-1.667h6.666ZM10 5a3.333 3.333 0 1 1 0 6.666A3.333 3.333 0 0 1 10 5Zm0 1.667A1.667 1.667 0 1 0 10 10a1.667 1.667 0 0 0 0-3.333Z"
          fill="#94A3B8"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgPassportIcon;
