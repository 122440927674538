import * as React from 'react';
import { SVGProps } from 'react';

const SvgErrorInputIcon = (props: SVGProps) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#fff"
        style={{
          mixBlendMode: 'multiply',
        }}
        d="M0 0h16v16H0z"
      />
      <path fill="#fff" d="M4 3h8v10H4z" />
      <path
        fill="#fff"
        style={{
          mixBlendMode: 'multiply',
        }}
        d="M0 0h16v16H0z"
      />
      <path
        d="M8 1C4.15 1 1 4.15 1 8s3.15 7 7 7 7-3.15 7-7-3.15-7-7-7Zm-.55 3h1.1v5.5h-1.1V4ZM8 12.5c-.4 0-.75-.35-.75-.75S7.6 11 8 11s.75.35.75.75-.35.75-.75.75Z"
        fill="#EF4444"
      />
    </svg>
  );
};
export default SvgErrorInputIcon;
