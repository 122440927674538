import * as React from 'react';
import { SVGProps } from 'react';

const SvgRequestIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.667 20.636H4.333V3.364h7.556V2H4.333C3.597 2 3 2.61 3 3.364v17.272C3 21.39 3.597 22 4.333 22h13.334c.736 0 1.333-.61 1.333-1.364V12h-1.333v8.636Z"
        fill="#9996A7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m20.614 4.305-.933-.923a1.33 1.33 0 0 0-1.866 0S10.48 10.63 10.37 11.07l-.33 1.306c-.113.445.019.917.347 1.241a1.33 1.33 0 0 0 1.255.344l1.32-.327c.44-.113 7.652-7.479 7.652-7.479a1.296 1.296 0 0 0 0-1.846v-.004Zm-7.129 7.052-.902-.892-.03-.026 6.195-6.13.933.923-6.196 6.125Z"
        fill="#9996A7"
      />
    </svg>
  );
};
export default SvgRequestIcon;
