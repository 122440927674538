import * as React from 'react';
import { SVGProps } from 'react';

const SvgFactor7X = (props: SVGProps) => {
  return (
    <svg
      width={34}
      height={35}
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={17} cy={17.91} r={16.5} fill="#67E8F9" stroke="#67E8F9" />
      <path
        d="m12.487 18.054 1.664-2.56h2.464l-2.88 4.048c.64.8 1.222 1.579 1.744 2.336.523.757.94 1.435 1.248 2.032h-2.56c-.085-.16-.192-.357-.32-.592a11.183 11.183 0 0 0-.432-.752 12.3 12.3 0 0 0-.512-.784 8.38 8.38 0 0 0-.512-.72c-.16.203-.325.427-.496.672-.16.245-.314.496-.464.752l-.448.736c-.138.245-.26.475-.368.688H8.183c.128-.267.304-.581.528-.944a29.113 29.113 0 0 1 1.552-2.288c.288-.395.571-.763.848-1.104-.48-.672-.96-1.35-1.44-2.032-.48-.693-.954-1.376-1.424-2.048h2.576l1.664 2.56Zm6.668 5.856c.064-.779.197-1.59.4-2.432.203-.843.448-1.67.736-2.48.299-.81.63-1.573.992-2.288.362-.725.73-1.35 1.104-1.872H17.57v-2.032h7.76v1.728c-.341.373-.72.89-1.136 1.552a19.224 19.224 0 0 0-1.168 2.256 22.004 22.004 0 0 0-.96 2.688c-.267.96-.432 1.92-.496 2.88h-2.416Z"
        fill="#2D3344"
      />
    </svg>
  );
};
export default SvgFactor7X;
