import * as React from 'react';
import { SVGProps } from 'react';

const SvgNetworkEthIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={12} cy={12.5} r={12} fill="#E3E3E3" />
      <path
        d="m11.998 3.904-.12.415v12.026l.12.121 5.552-3.3-5.552-9.262Z"
        fill="#343434"
      />
      <path d="m11.998 3.904-5.551 9.263 5.551 3.3V3.903Z" fill="#8C8C8C" />
      <path
        d="m11.998 17.523-.068.084v4.284l.068.2 5.555-7.866-5.555 3.298Z"
        fill="#3C3C3B"
      />
      <path
        d="M11.998 22.092v-4.569l-5.551-3.298 5.551 7.867Z"
        fill="#8C8C8C"
      />
      <path d="m11.998 16.466 5.552-3.3-5.552-2.537v5.837Z" fill="#141414" />
      <path d="m6.447 13.167 5.551 3.3v-5.838l-5.551 2.538Z" fill="#393939" />
    </svg>
  );
};
export default SvgNetworkEthIcon;
