import * as React from 'react';
import { SVGProps } from 'react';

const SvgCopyIcon = (props: SVGProps) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.833 5V2.5a.833.833 0 0 1 .834-.833h10a.833.833 0 0 1 .833.833v11.667a.833.833 0 0 1-.833.833h-2.5v2.5c0 .46-.375.833-.84.833H3.34A.833.833 0 0 1 2.5 17.5l.002-11.667c0-.46.376-.833.839-.833h2.492ZM4.168 6.667l-.001 10H12.5v-10H4.168ZM7.5 5h6.667v8.333h1.666v-10H7.5V5ZM5.833 9.167h5v1.666h-5V9.167Zm0 3.333h5v1.667h-5V12.5Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgCopyIcon;
