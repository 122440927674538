import * as React from 'react';
import { SVGProps } from 'react';

const SvgRedirectIcon = (props: SVGProps) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="#E0E0E0"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M3.333 14c-.366 0-.68-.13-.942-.392A1.281 1.281 0 0 1 2 12.667V3.333c0-.366.13-.68.392-.942.261-.261.575-.391.941-.391H8v1.333H3.333v9.334h9.334V8H14v4.667c0 .366-.13.68-.392.942a1.282 1.282 0 0 1-.941.391H3.333Zm3.134-3.533-.934-.934 6.2-6.2h-2.4V2H14v4.667h-1.333v-2.4l-6.2 6.2Z" />
    </svg>
  );
};
export default SvgRedirectIcon;
