import * as React from 'react';
import { SVGProps } from 'react';

const SvgBonusBalanceIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="svg-fill"
        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 12 20Z"
        fill="#F8FAFC"
      />
      <path
        className="svg-fill"
        d="M14.8 8 16 9.2 9.2 16 8 14.8 14.8 8ZM9.5 8c.83 0 1.5.67 1.5 1.5S10.33 11 9.5 11 8 10.33 8 9.5 8.67 8 9.5 8Zm5 5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5Z"
        fill="#F8FAFC"
      />
    </svg>
  );
};
export default SvgBonusBalanceIcon;
