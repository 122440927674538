import * as React from 'react';
import { SVGProps } from 'react';

const SvgDiamondOrangeIcon = (props: SVGProps) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m11.347 5.92-2.311 7.705L15.2 5.92h-3.853ZM4.653 5.92H.8l6.164 7.705L4.653 5.92ZM15.058 5.07 11.99 2.003a.422.422 0 0 0-.298-.124H10.13l1.197 3.193h3.732ZM4.674 5.07l1.197-3.192H4.31a.422.422 0 0 0-.299.124L.941 5.07h3.733ZM10.46 5.92H5.54L8 14.122l2.46-8.202ZM5.581 5.07h4.837L9.221 1.879H6.78L5.58 5.071Z"
        fill="#FF822E"
      />
    </svg>
  );
};
export default SvgDiamondOrangeIcon;
