import * as React from 'react';
import { SVGProps } from 'react';

const SvgMinusIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5 11h14v2H5v-2Z" fill="#fff" />
    </svg>
  );
};
export default SvgMinusIcon;
