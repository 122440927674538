import * as React from 'react';
import { SVGProps } from 'react';

const SvgHistoryDepositIcon = (props: SVGProps) => {
  return (
    <svg
      width={29}
      height={27}
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3.739a1.2 1.2 0 0 0-1.41-1.181L2.99 4.986A1.2 1.2 0 0 0 2 6.167V20a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-3V3.739ZM16 7V4.694L4 6.837V7h12ZM4 9v10h16V9H4Zm11 4h3v2h-3v-2Z"
        fill="#F8FAFC"
      />
      <rect
        x={15.5}
        y={13.5}
        width={13}
        height={13}
        rx={6.5}
        fill="#2D3344"
        stroke="#2D3344"
      />
      <path d="M22.5 20v4h-1v-4H18l4-4 4 4h-3.5Z" fill="#22C55E" />
    </svg>
  );
};
export default SvgHistoryDepositIcon;
