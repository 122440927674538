import * as React from 'react';
import { SVGProps } from 'react';

const SvgDiamondStatus = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.993 68C52.715 68 68 52.728 68 34.007 68 15.285 52.728 0 33.993 0 15.272 0 0 15.272 0 34.007 0 52.728 15.272 68 33.993 68Z"
        fill="#FFD04E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.947 32.067c.04.642.053 1.284.053 1.94C68 52.781 52.782 68 33.993 68 15.218 68 0 52.782 0 34.006c0-.655.027-1.297.053-1.939 1.003 17.88 15.82 32.068 33.94 32.068 18.134 0 32.95-14.188 33.953-32.068Z"
        fill="#AA8215"
      />
      <path
        d="M8.759 19.686a39.472 39.472 0 0 1 5.208-3.69c.217-.128.474.096.376.328-1.398 3.31-3.488 6.484-6.346 8.668.125-1.782.364-3.564.762-5.306Zm-.762 5.308a39.471 39.471 0 0 1-3.082-6.04c-.099-.242-.452-.208-.5.048-.743 3.982.156 8.88 3.634 11.277a34.981 34.981 0 0 1-.052-5.285Zm.052 5.283a37.964 37.964 0 0 1 3.857-4.906c.17-.182.476-.047.453.2-.32 3.484-1.409 7.066-3.478 9.883a35.237 35.237 0 0 1-.832-5.177Zm.831 5.178a40.94 40.94 0 0 1-4.721-4.738c-.168-.197-.491-.058-.46.199.48 3.964 2.799 8.279 6.76 9.52a35.426 35.426 0 0 1-1.579-4.98Zm1.58 4.98a37.35 37.35 0 0 1 2.266-5.773c.11-.222.44-.182.491.06.713 3.406.685 7.135-.463 10.41a35.387 35.387 0 0 1-2.295-4.697Zm2.294 4.699a41.746 41.746 0 0 1-5.885-3.141c-.218-.14-.486.088-.381.324 1.614 3.654 5.094 7.098 9.243 7.145a35.176 35.176 0 0 1-2.977-4.328Zm2.976 4.326a36.548 36.548 0 0 1 .47-6.208c.04-.245.37-.303.489-.085 1.684 3.072 2.764 6.674 2.668 10.164a34.925 34.925 0 0 1-3.626-3.87Zm42.93-29.774a39.475 39.475 0 0 0-5.207-3.69c-.218-.128-.475.096-.377.328 1.398 3.31 3.489 6.484 6.346 8.668a34.841 34.841 0 0 0-.761-5.306Zm.762 5.308a39.462 39.462 0 0 0 3.083-6.04c.098-.242.451-.208.5.048.743 3.982-.157 8.88-3.634 11.277.142-1.756.176-3.527.051-5.285Zm-.052 5.283a37.97 37.97 0 0 0-3.856-4.906c-.17-.182-.477-.047-.454.2.32 3.484 1.41 7.066 3.479 9.883.397-1.702.692-3.434.831-5.177Zm-.83 5.178a40.94 40.94 0 0 0 4.72-4.738c.168-.197.492-.058.46.199-.48 3.964-2.798 8.279-6.76 9.52a35.417 35.417 0 0 0 1.58-4.98Zm-1.58 4.98a37.35 37.35 0 0 0-2.266-5.773c-.11-.222-.441-.182-.492.06-.713 3.406-.685 7.135.464 10.41a35.4 35.4 0 0 0 2.294-4.697Zm-2.295 4.699a41.745 41.745 0 0 0 5.885-3.141c.218-.14.486.088.382.324-1.615 3.654-5.095 7.098-9.244 7.145a35.167 35.167 0 0 0 2.977-4.328Z"
        fill="#fff"
      />
      <path
        d="M51.689 49.46a36.569 36.569 0 0 0-.47-6.208c-.04-.245-.37-.303-.488-.085-1.685 3.072-2.764 6.674-2.668 10.164a34.936 34.936 0 0 0 3.626-3.87Z"
        fill="#fff"
      />
      <path
        d="m33.9 20.67-6.783 8.734-6.673-8.591c.21-.092.44-.142.675-.142H33.9Z"
        fill="#B1E4F9"
      />
      <path
        d="m47.355 20.813-6.673 8.591-6.783-8.733h12.78c.236 0 .466.05.675.142Z"
        fill="#B1E4F9"
      />
      <path
        d="M54.197 29.404H40.682l6.672-8.591h.001c.235.103.445.26.613.461l5.84 6.995c.275.328.404.734.39 1.135Z"
        fill="#75CEF9"
      />
      <path d="m33.9 20.67-6.783 8.734h13.565L33.9 20.67Z" fill="#90D8F9" />
      <path
        d="M27.117 29.404H13.6a1.667 1.667 0 0 1 .389-1.135l5.84-6.995c.169-.201.378-.358.613-.462h.001l6.673 8.592Z"
        fill="#E4F6FF"
      />
      <path
        d="m40.682 29.404-6.54 21.662a1.69 1.69 0 0 1-.486 0l-6.54-21.662h13.566Z"
        fill="#75CEF9"
      />
      <path
        d="M33.656 51.066a1.667 1.667 0 0 1-.984-.516l-.003-.003-18.621-20.06-.007-.007a1.67 1.67 0 0 1-.44-1.076h13.515l6.54 21.662Z"
        fill="#B1E4F9"
      />
      <path
        d="M54.197 29.404a1.67 1.67 0 0 1-.44 1.076l-.006.008L35.13 50.547l-.004.003c-.27.291-.62.463-.983.516l6.539-21.662h13.515Z"
        fill="#6BBEF6"
      />
      <path
        d="m47.626 38.162.595-.298a.336.336 0 0 0 0-.6l-.595-.298a1.313 1.313 0 0 1-.633-.687l-.488-1.221a.336.336 0 0 0-.623 0l-.489 1.22c-.12.3-.344.544-.632.688l-.595.297a.336.336 0 0 0 0 .601l.595.298c.288.144.513.388.632.687l.489 1.22c.112.282.51.282.623 0l.488-1.22c.12-.299.345-.543.633-.687Zm-20.094 6.995.595-.298a.336.336 0 0 0 0-.6l-.595-.298a1.314 1.314 0 0 1-.633-.687l-.488-1.221a.336.336 0 0 0-.624 0l-.488 1.22c-.12.3-.345.544-.632.688l-.596.298a.336.336 0 0 0 0 .6l.596.298c.287.144.513.388.632.687l.488 1.221a.336.336 0 0 0 .624 0l.488-1.22c.12-.3.345-.544.633-.688ZM23.2 21.443l.942-.472a.336.336 0 0 0 0-.6l-.943-.471a1.695 1.695 0 0 1-.816-.887l-.72-1.802a.336.336 0 0 0-.624 0l-.72 1.802a1.695 1.695 0 0 1-.816.886l-.943.472a.336.336 0 0 0 0 .6l.943.472c.371.185.661.5.816.886l.72 1.802a.336.336 0 0 0 .624 0l.72-1.802c.155-.385.445-.7.816-.886Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgDiamondStatus;
