import * as React from 'react';
import { SVGProps } from 'react';

const SvgCloseMobileIcon = (props: SVGProps) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m12.348 9.999 7.16-7.145A1.672 1.672 0 0 0 17.142.49L10 7.65 2.857.49A1.672 1.672 0 1 0 .493 2.854L7.653 10l-7.16 7.144a1.665 1.665 0 0 0 0 2.364 1.664 1.664 0 0 0 2.364 0L10 12.347l7.143 7.16a1.664 1.664 0 0 0 2.364 0 1.666 1.666 0 0 0 0-2.364l-7.16-7.144Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgCloseMobileIcon;
