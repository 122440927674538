import * as React from 'react';
import { SVGProps } from 'react';

const SvgVerifyBonusIcon = (props: SVGProps) => {
  return (
    <svg
      width={33}
      height={32}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.4}>
        <path
          d="m28.647 15.748-6.06-.88-2.707-5.491a.767.767 0 0 0-1.37 0l-2.709 5.49-6.058.881a.762.762 0 0 0-.423 1.303l4.384 4.274-1.036 6.035a.762.762 0 0 0 1.107.804l5.42-2.85 5.419 2.85a.762.762 0 0 0 1.107-.804l-1.035-6.035 4.383-4.274a.762.762 0 0 0 .222-.437.76.76 0 0 0-.644-.866Z"
          fill="#FFD04E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.153 19.54a1.502 1.502 0 0 0-.428-.49 2.844 2.844 0 0 0-.651-.356 10.69 10.69 0 0 0-.825-.287c-.154-.046-.29-.09-.407-.13a1.713 1.713 0 0 1-.293-.13.578.578 0 0 1-.177-.147.31.31 0 0 1-.058-.19c0-.073.017-.134.054-.185a.399.399 0 0 1 .148-.123.778.778 0 0 1 .215-.069c.081-.014.168-.021.26-.021.091 0 .18.012.267.037a1.605 1.605 0 0 1 .47.22c.069.047.131.093.188.137l.037.032c.04.03.076.057.108.077.032.021.068.032.108.032.047 0 .095-.022.143-.066l.645-.637a.624.624 0 0 0 .082-.097.17.17 0 0 0 .028-.094c0-.034-.02-.074-.057-.121a1.16 1.16 0 0 0-.154-.15 2.378 2.378 0 0 0-.225-.162 4.985 4.985 0 0 0-.273-.16 2.82 2.82 0 0 0-.693-.256c-.072-.016-.145-.025-.218-.036v-.528a.144.144 0 0 0-.069-.125.242.242 0 0 0-.133-.047h-.793c-.062 0-.108.017-.138.051a.172.172 0 0 0-.047.12v.549c-.088.015-.18.028-.262.051-.275.077-.51.186-.706.326-.195.14-.345.31-.45.507a1.378 1.378 0 0 0-.16.66c0 .227.043.433.127.62.085.188.21.36.38.514.169.155.38.296.632.422.253.126.549.244.886.353.076.023.165.053.27.088.104.035.204.077.3.127.095.05.175.105.242.17.066.063.099.135.099.216a.602.602 0 0 1-.036.207.423.423 0 0 1-.12.172.62.62 0 0 1-.23.118c-.097.03-.217.046-.36.046-.155 0-.303-.025-.446-.075a2.085 2.085 0 0 1-.423-.206c-.096-.06-.18-.12-.256-.182a7.562 7.562 0 0 0-.183-.146.801.801 0 0 0-.101-.07.168.168 0 0 0-.08-.024c-.037 0-.085.02-.144.058l-.73.645c-.064.054-.096.104-.096.148 0 .041.032.093.097.156l.017.016.017.015.012.012c.135.138.29.267.466.387.176.12.363.224.56.314.199.09.406.16.62.21.02.005.039.006.058.01v.574c0 .114.062.172.185.172h.793c.135 0 .202-.058.202-.172v-.561c.127-.022.252-.045.371-.081.298-.09.558-.217.779-.38.22-.165.394-.363.52-.596.127-.233.19-.492.19-.778 0-.271-.051-.502-.154-.692Z"
          fill="#2D3344"
        />
      </g>
      <path
        d="m24.561 11.516-6.462-.94-2.89-5.856a.818.818 0 0 0-1.461 0l-2.888 5.857-6.463.939a.814.814 0 0 0-.45 1.39l4.675 4.558-1.104 6.438a.813.813 0 0 0 1.18.857l5.781-3.039 5.78 3.04a.813.813 0 0 0 1.181-.858l-1.104-6.438 4.676-4.558a.813.813 0 0 0 .237-.466.812.812 0 0 0-.688-.924Z"
        fill="#FFD04E"
      />
      <path
        d="M17.53 14.66v1.352h-5.745V14.66h5.745Zm-2.142-2.31v6.103h-1.456V12.35h1.456Z"
        fill="#1E293B"
      />
    </svg>
  );
};
export default SvgVerifyBonusIcon;
