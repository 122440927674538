import * as React from 'react';
import { SVGProps } from 'react';

const SvgAccountIcon = (props: SVGProps) => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={14} cy={14} r={14} fill="url(#a)" />
      <path
        d="M20.667 22.333H19v-1.666a2.5 2.5 0 0 0-2.5-2.5h-5a2.5 2.5 0 0 0-2.5 2.5v1.666H7.333v-1.666A4.167 4.167 0 0 1 11.5 16.5h5a4.167 4.167 0 0 1 4.167 4.167v1.666ZM14 14.833a5 5 0 1 1 0-10 5 5 0 0 1 0 10Zm0-1.666A3.333 3.333 0 1 0 14 6.5a3.333 3.333 0 0 0 0 6.667Z"
        fill="#000"
      />
      <defs>
        <linearGradient
          id="a"
          x1={14}
          y1={0}
          x2={14}
          y2={28}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgAccountIcon;
