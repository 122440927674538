import * as React from 'react';
import { SVGProps } from 'react';

const SvgVerifyProfileIcon = (props: SVGProps) => {
  return (
    <svg
      width={27}
      height={24}
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="svg-fill"
        d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2Zm-8-9A6 6 0 1 1 12 .998 6 6 0 0 1 12 13Zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
        fill="#1F2937"
      />
      <path
        className="svg-fill"
        d="M25 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
      />
    </svg>
  );
};
export default SvgVerifyProfileIcon;
