import * as React from 'react';
import { SVGProps } from 'react';

const SvgMoneyCirculationIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="svg-fill"
        d="M19.375 15.103A8 8 0 0 0 8.03 5.053l-.992-1.737A9.996 9.996 0 0 1 17 3.34c4.49 2.592 6.21 8.142 4.117 12.77l1.342.774-4.165 2.214-.165-4.714 1.246.719ZM4.625 8.897a8.001 8.001 0 0 0 11.345 10.05l.992 1.737A9.996 9.996 0 0 1 7 20.66C2.51 18.068.79 12.518 2.883 7.89L1.54 7.117l4.165-2.214.165 4.714-1.246-.719.001-.001ZM8.5 14H14a.5.5 0 0 0 0-1h-4a2.5 2.5 0 0 1 0-5h1V7h2v1h2.5v2H10a.5.5 0 0 0 0 1h4a2.5 2.5 0 1 1 0 5h-1v1h-2v-1H8.5v-2Z"
        fill="#F8FAFC"
      />
    </svg>
  );
};
export default SvgMoneyCirculationIcon;
