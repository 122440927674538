import * as React from 'react';
import { SVGProps } from 'react';

const SvgVerificationPendingIcon = (props: SVGProps) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)" fill="#C89000">
        <path d="M9.999 18.333a8.333 8.333 0 1 1 0-16.666 8.333 8.333 0 0 1 0 16.666Zm3.75-2.916v-1.25L10 10l3.75-4.583V4.309h-7.5v1.107L10 10v-.834L8.333 7.083 10 7.5l1.666-.417L10 9.166V10l.417 4.166 2.917.417V15H6.666v-.417l2.917-.417L9.999 10l-3.75 4.166v1.25h7.5Z" />
        <path d="M9.999 18.333a8.333 8.333 0 1 1 0-16.666 8.333 8.333 0 0 1 0 16.666Zm3.75-2.916v-1.25L10 10l3.75-4.583V4.309h-7.5v1.107L10 10v-.834L8.333 7.083 10 7.5l1.666-.417L10 9.166V10l.417 4.166 2.917.417V15H6.666v-.417l2.917-.417L9.999 10l-3.75 4.166v1.25h7.5Z" />
        <path d="M9.999 18.333a8.333 8.333 0 1 1 0-16.666 8.333 8.333 0 0 1 0 16.666Zm3.75-2.916v-1.25L10 10l3.75-4.583V4.309h-7.5v1.107L10 10v-.834l-2.083-2.5L10 7.083l2.084-.417-2.084 2.5V10l.417 3.75 2.917.833V15H6.666v-.417l2.917-.833.416-3.75-3.75 4.166v1.25h7.5Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgVerificationPendingIcon;
