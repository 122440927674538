import * as React from 'react';
import { SVGProps } from 'react';

const SvgStarDollarIcon = (props: SVGProps) => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m24.83 9.655-6.942-1.01-3.103-6.291a.879.879 0 0 0-1.57 0l-3.103 6.292-6.943 1.009a.873.873 0 0 0-.484 1.493l5.023 4.897-1.187 6.915a.873.873 0 0 0 1.27.922L14 20.617l6.21 3.265a.874.874 0 0 0 1.269-.921l-1.187-6.916 5.023-4.897a.873.873 0 0 0 .254-.5.872.872 0 0 0-.738-.993Z"
        fill="#EAB308"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.624 14a1.719 1.719 0 0 0-.49-.562 3.256 3.256 0 0 0-.747-.407 12.208 12.208 0 0 0-.945-.33 11.761 11.761 0 0 1-.466-.149 1.962 1.962 0 0 1-.336-.147.664.664 0 0 1-.203-.17.356.356 0 0 1-.067-.218c0-.083.02-.154.062-.211a.458.458 0 0 1 .17-.142.89.89 0 0 1 .246-.078c.093-.016.193-.025.3-.025.103 0 .204.014.304.043a1.84 1.84 0 0 1 .539.253c.079.053.15.106.215.156l.043.036c.045.036.086.065.123.09a.223.223 0 0 0 .124.035c.055 0 .11-.025.164-.076l.74-.729a.715.715 0 0 0 .094-.112.194.194 0 0 0 .031-.107c0-.039-.022-.085-.065-.139a1.31 1.31 0 0 0-.176-.172 2.692 2.692 0 0 0-.259-.186 5.618 5.618 0 0 0-.312-.183 3.228 3.228 0 0 0-.794-.293c-.082-.018-.167-.028-.25-.041V9.53a.166.166 0 0 0-.079-.144.277.277 0 0 0-.153-.053h-.908c-.071 0-.124.02-.159.058a.197.197 0 0 0-.054.139v.627c-.1.019-.205.033-.3.06a2.544 2.544 0 0 0-.809.373c-.223.16-.395.354-.516.581a1.578 1.578 0 0 0-.182.756c0 .26.049.497.145.711a1.8 1.8 0 0 0 .435.588c.194.178.435.34.725.483.29.145.628.28 1.014.405.088.027.19.061.31.101.12.04.234.089.343.146.11.056.202.12.278.194a.338.338 0 0 1 .114.248.69.69 0 0 1-.041.237.485.485 0 0 1-.138.197.708.708 0 0 1-.264.136c-.11.035-.248.052-.412.052-.178 0-.348-.028-.511-.085a2.395 2.395 0 0 1-.485-.237 2.903 2.903 0 0 1-.293-.208 8.362 8.362 0 0 0-.21-.168.912.912 0 0 0-.116-.08.192.192 0 0 0-.091-.027c-.042 0-.097.022-.165.067l-.836.738c-.074.063-.11.12-.11.17 0 .047.036.107.11.18l.02.017.019.018.014.013c.155.158.333.306.534.443.202.137.416.257.643.36.227.103.464.183.71.241.022.006.044.007.065.012v.657c0 .131.072.197.213.197h.908c.155 0 .232-.066.232-.197v-.643c.145-.025.289-.052.425-.092a2.87 2.87 0 0 0 .892-.437c.253-.188.452-.415.597-.682a1.84 1.84 0 0 0 .217-.892c0-.31-.058-.574-.176-.792Z"
        fill="#0F172A"
      />
    </svg>
  );
};
export default SvgStarDollarIcon;
