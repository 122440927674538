import * as React from 'react';
import { SVGProps } from 'react';

const SvgDiscountEmptyIcon = (props: SVGProps) => {
  return (
    <svg
      width={53}
      height={52}
      viewBox="0 0 53 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m32.567 17.334 2.6 2.6-14.734 14.733-2.6-2.6 14.734-14.733Zm-23.4-8.667h34.666A4.318 4.318 0 0 1 48.167 13v8.667a4.333 4.333 0 1 0 0 8.667V39a4.318 4.318 0 0 1-4.334 4.334H9.167A4.334 4.334 0 0 1 4.833 39v-8.666A4.318 4.318 0 0 0 9.167 26a4.333 4.333 0 0 0-4.334-4.333V13a4.333 4.333 0 0 1 4.334-4.333Zm0 4.333v5.504a8.654 8.654 0 0 1 0 14.993V39h34.666v-5.503a8.653 8.653 0 0 1 0-14.993V13H9.167Zm11.916 4.334a3.246 3.246 0 0 1 3.25 3.25 3.246 3.246 0 0 1-3.25 3.25 3.246 3.246 0 0 1-3.25-3.25 3.246 3.246 0 0 1 3.25-3.25Zm10.834 10.833a3.246 3.246 0 0 1 3.25 3.25 3.246 3.246 0 0 1-3.25 3.25 3.246 3.246 0 0 1-3.25-3.25 3.246 3.246 0 0 1 3.25-3.25Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgDiscountEmptyIcon;
