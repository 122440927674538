import * as React from 'react';
import { SVGProps } from 'react';

const SvgPhoneProfileIllustration = (props: SVGProps) => {
  return (
    <svg
      width={260}
      height={260}
      viewBox="0 0 260 260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M96.898 189.237c.1-7.976-5.456-24.575-13.582-35.028-8.126-10.453-21.031-18.186-30.237-15.138-8.62 2.846-7.825 14.147 2.51 21.763 10.336 7.615 25.86 17.203 30.288 30.225l9.415 15.953 1.606-17.775Z"
        fill="#FFD04E"
      />
      <path
        opacity={0.1}
        d="M96.898 189.237c.1-7.976-5.456-24.575-13.582-35.028-8.126-10.453-21.031-18.186-30.237-15.138-8.62 2.846-7.825 14.147 2.51 21.763 10.336 7.615 25.86 17.203 30.288 30.225l9.415 15.953 1.606-17.775Z"
        fill="#000"
      />
      <path
        d="M97.359 200.16a.579.579 0 0 1-.519-.478c-5.021-27.296-26.697-49.697-39.777-54.743a.574.574 0 0 1-.369-.301.589.589 0 0 1 .787-.79c13.332 5.147 35.426 27.917 40.497 55.624a.582.582 0 0 1-.46.68l-.16.008Z"
        fill="#42495F"
      />
      <path
        d="M93.1 217.54c-1.223-4.5-6.595-11.964-15.35-18.186-9.69-6.876-22.01-10.134-24.796-15.432-3.347-6.364 2.034-12.275 12.051-10.914 10.018 1.36 27.492 15.331 30.07 36.035l-1.976 8.497Z"
        fill="#FFD04E"
      />
      <path
        d="M95.1 212.855a.585.585 0 0 1-.502-.386c-8.043-23.433-25.684-31.838-33.886-33.005a.577.577 0 0 1-.494-.655.582.582 0 0 1 .653-.495c9.072 1.293 26.621 9.857 34.831 33.769a.599.599 0 0 1-.36.747c-.078.025-.16.033-.242.025Z"
        fill="#42495F"
      />
      <path
        d="M202.69 203.856a15.135 15.135 0 0 1 7.603 5.564 7.683 7.683 0 0 1 1.942 4.646c0 1.709-1.417 3.48-3.15 3.365a4.463 4.463 0 0 1-2.625-1.458c-1.785-1.709-3.486-3.897-5.943-4.168-1.407-.156-2.92.355-4.201-.26a3.132 3.132 0 0 1-1.344-1.511 3.098 3.098 0 0 1-.136-2.01 5.435 5.435 0 0 1 2.467-3.126 6.19 6.19 0 0 1 5.387-1.042ZM206.651 190.313c-.432 2.909 2.538 4.572 4.675 4.541 2.137-.03 4.11-1.125 5.929-2.2 4.203-2.453 8.581-5.068 11.129-9.123a7.785 7.785 0 0 0 1.356-4.055 4.074 4.074 0 0 0-.496-2.074 4.151 4.151 0 0 0-1.477-1.555c-1.52-.821-3.401-.345-5.014.264a34.269 34.269 0 0 0-12.588 8.272 11.749 11.749 0 0 0-3.514 5.98M200.681 189.807a8.486 8.486 0 0 0 3.813-1.703 8.975 8.975 0 0 0 2.617-3.357 16.57 16.57 0 0 0 1.26-8.545 27.26 27.26 0 0 0-2.71-9.797 9.27 9.27 0 0 0-2.31-3.203 3.934 3.934 0 0 0-1.719-.872 3.831 3.831 0 0 0-1.911.059c-1.8.647-2.59 2.806-3.07 4.736a68.252 68.252 0 0 0-1.76 10.35c-.27 2.806-.33 5.759.84 8.346 1.17 2.588 3.16 4.34 5.34 3.965"
        fill="#FFD04E"
      />
      <path
        d="M180.974 217.436c.318-.21.616-.453.887-.725l2.327-2.068a41.917 41.917 0 0 1 3.591-2.857 34.244 34.244 0 0 1 4.833-2.803 19.114 19.114 0 0 1 5.271-1.663 9.873 9.873 0 0 1 4.457.288 9.4 9.4 0 0 1 2.714 1.353c.287.253.598.474.929.661a5.934 5.934 0 0 0-.814-.81 8.656 8.656 0 0 0-2.735-1.524 9.8 9.8 0 0 0-4.603-.405c-1.875.276-3.698.84-5.407 1.673a32.67 32.67 0 0 0-4.895 2.868 39.145 39.145 0 0 0-3.56 2.963 58.487 58.487 0 0 0-2.233 2.186c-.283.26-.538.55-.762.863Z"
        fill="#FFD04E"
      />
      <path
        d="M182.849 215.555c.14-.104.269-.222.384-.352.259-.29.601-.642 1.037-1.036.881-.942 2.166-2.287 3.794-3.912 3.235-3.261 6.915-6.574 12.265-11.118 5.028-4.265 10.969-9.141 15.127-12.039a87.04 87.04 0 0 1 2.55-1.76c.757-.507 1.41-.952 1.959-1.284l1.234-.817c.149-.085.288-.186.415-.301a1.77 1.77 0 0 0-.456.228l-1.296.735c-.56.311-1.224.735-1.991 1.222-.767.486-1.659 1.035-2.602 1.708a167.972 167.972 0 0 0-14.276 10.941c-5.361 4.555-9.985 8.934-13.167 12.257a100.478 100.478 0 0 0-3.681 4.016l-.954 1.139c-.129.11-.244.235-.342.373ZM190.977 206.773c.375-.356.723-.738 1.042-1.143a41.67 41.67 0 0 0 2.543-3.376 54.146 54.146 0 0 0 3.22-5.392 50.257 50.257 0 0 0 3.126-7.034 38.556 38.556 0 0 0 2.574-13.671 18.347 18.347 0 0 0-.156-2.411c0-.353 0-.685-.115-1.038-.114-.354-.114-.561-.167-.811a10.832 10.832 0 0 0-.375-1.506c.281 1.893.445 3.801.49 5.714a40.424 40.424 0 0 1-2.688 13.505 56.985 56.985 0 0 1-3.064 6.992 61.437 61.437 0 0 1-3.126 5.402 54.21 54.21 0 0 1-2.418 3.449 9.45 9.45 0 0 0-.886 1.32Z"
        fill="#42495F"
      />
      <path
        d="M61.011 232.428c43.464 3.43 113.934 3.43 157.393 0s43.464-8.995 0-12.421-113.929-3.429-157.393 0c-43.464 3.43-43.459 8.992 0 12.421Z"
        fill="#fff"
        fillOpacity={0.4}
      />
      <path d="M163.966 213.949v-74.526h-.987v74.526h.987Z" fill="#E6E6E6" />
      <path d="M194.053 139.423h-31.074v1.254h31.074v-1.254Z" fill="#E6E6E6" />
      <path
        d="m94.603 112.017 13.592-64.954h15.006l-13.599 64.954h-15Z"
        fill="#42495F"
      />
      <path
        d="M181.672 223.292H99.105a11.074 11.074 0 0 1-8.162-3.482 11.07 11.07 0 0 1-2.981-8.358L97.995 39.84A12.757 12.757 0 0 1 110.538 28h82.586a11.068 11.068 0 0 1 11.144 11.84l-10.066 171.612a12.756 12.756 0 0 1-12.53 11.84Z"
        fill="#FFD04E"
      />
      <path
        opacity={0.8}
        d="M181.672 223.292H99.105a11.074 11.074 0 0 1-8.162-3.482 11.07 11.07 0 0 1-2.981-8.358L97.995 39.84A12.757 12.757 0 0 1 110.538 28h82.586a11.068 11.068 0 0 1 11.144 11.84l-10.066 171.612a12.756 12.756 0 0 1-12.53 11.84Z"
        fill="#42495F"
      />
      <path
        d="M181.184 219.855h-80.272a7.768 7.768 0 0 1-7.398-5.16 7.777 7.777 0 0 1-.434-3.155l10.033-170.866a8.967 8.967 0 0 1 8.78-8.322h80.272a7.783 7.783 0 0 1 7.833 8.322L189.964 211.54a8.964 8.964 0 0 1-8.78 8.315Z"
        fill="#FFD04E"
      />
      <path
        d="M180.319 218.94h-80.272a7.78 7.78 0 0 1-7.398-5.166 7.781 7.781 0 0 1-.435-3.156l10.034-170.866a8.968 8.968 0 0 1 8.78-8.322H191.3a7.785 7.785 0 0 1 7.398 5.166 7.77 7.77 0 0 1 .434 3.156l-10.034 170.866a8.965 8.965 0 0 1-8.779 8.322Z"
        fill="#42495F"
      />
      <path
        opacity={0.1}
        d="M177.973 212.587h-74.86a4.806 4.806 0 0 1-4.841-5.142l4.214-71.273a5.54 5.54 0 0 1 5.444-5.142h74.828a4.803 4.803 0 0 1 3.545 1.513 4.806 4.806 0 0 1 1.296 3.629l-4.183 71.273a5.535 5.535 0 0 1-5.443 5.142ZM161.348 44.105H139.53a1.716 1.716 0 0 1-1.731-1.838 1.985 1.985 0 0 1 1.951-1.844h21.811a1.721 1.721 0 0 1 1.66 1.139c.081.226.114.466.096.705a1.978 1.978 0 0 1-1.969 1.837ZM170.028 112.892c13.435-13.046 14.352-33.893 2.049-46.563-12.304-12.67-33.169-12.363-46.603.683-13.435 13.047-14.352 33.894-2.049 46.563 12.303 12.67 33.168 12.364 46.603-.683Z"
        fill="#FFD04E"
      />
      <path
        d="M124.686 111.765a29.114 29.114 0 0 1-7.82-21.95h.627a28.51 28.51 0 0 0 7.651 21.479l-.458.471ZM178.632 90.085l-.628-.038a30.012 30.012 0 0 0-.627-8.24l.627-.138c.6 2.763.811 5.595.628 8.416ZM175.559 76.351a28.224 28.224 0 0 0-15.898-14.856l.219-.59A28.766 28.766 0 0 1 176.129 76.1l-.57.251Z"
        fill="#FFD04E"
      />
      <path
        d="M167.161 109.941c11.708-11.37 12.509-29.535 1.788-40.575-10.72-11.04-28.902-10.772-40.61.597-11.708 11.37-12.508 29.536-1.788 40.576 10.721 11.039 28.903 10.772 40.61-.598Z"
        fill="#FFD04E"
      />
      <path
        d="M148.708 71.108c-10.094.264-18.76 8.66-19.344 18.74-.585 10.08 7.132 18.045 17.225 17.781 10.093-.264 18.759-8.66 19.344-18.74.585-10.079-7.132-18.045-17.225-17.78Zm-10.739 29.964c.881-1.664 5.765-3.396 9.203-3.486 3.437-.09 8.153 1.39 8.826 3.014-2.602 2.037-5.796 3.288-9.197 3.377-3.401.089-6.46-.998-8.832-2.905Zm20.797-3.188c-2.431-3.109-8.713-4.02-11.382-3.95-2.67.07-9.077 1.313-11.876 4.559-1.796-2.48-2.67-5.552-2.487-8.741.467-8.053 7.411-14.78 15.475-14.992 8.063-.21 14.247 6.173 13.78 14.225-.193 3.324-1.503 6.403-3.51 8.899Zm-10.482-19.472c-3.547.093-6.565 3.017-6.77 6.56-.206 3.542 2.481 6.316 6.028 6.223 3.548-.093 6.565-3.017 6.771-6.56.205-3.542-2.482-6.316-6.029-6.223Zm-.53 9.13c-1.517.04-2.671-1.151-2.584-2.667.088-1.515 1.384-2.77 2.902-2.81 1.518-.04 2.672 1.151 2.584 2.667-.088 1.515-1.384 2.77-2.902 2.81Z"
        fill="#42495F"
      />
      <path
        d="m137.981 96.425-.156.22c.05-.076.106-.145.163-.214l-.007-.006ZM146.893 91.013c.031-.144.056-.282.081-.42-.025.12-.05.276-.081.42Z"
        fill="#263238"
      />
      <path
        opacity={0.2}
        d="M146.893 91.013c.031-.144.056-.282.081-.42-.025.12-.05.276-.081.42Z"
        fill="#000"
      />
      <path d="M147.119 88.78Z" fill="#263238" />
      <path opacity={0.2} d="M147.119 88.78Z" fill="#000" />
      <path
        d="M182.419 40.085h8.836a.965.965 0 0 0 .947-.897.827.827 0 0 0-.225-.629.834.834 0 0 0-.615-.262h-8.842a.965.965 0 0 0-.947.89.826.826 0 0 0 .225.636.837.837 0 0 0 .621.262ZM182.237 43.164h8.836a.965.965 0 0 0 .947-.897.828.828 0 0 0-.504-.83.833.833 0 0 0-.336-.067h-8.836a.966.966 0 0 0-.953.897.852.852 0 0 0 .229.631.845.845 0 0 0 .617.266ZM110.752 43.164h8.836a.97.97 0 0 0 .953-.897.839.839 0 0 0-.847-.897h-8.836a.961.961 0 0 0-.947.897.843.843 0 0 0 .841.897ZM182.055 46.237h8.855a.966.966 0 0 0 .954-.897.853.853 0 0 0-.511-.828.845.845 0 0 0-.336-.069h-8.855a.97.97 0 0 0-.953.897.839.839 0 0 0 .846.897Z"
        fill="#FFD04E"
      />
      <path
        d="M170.441 158.749h-53.456a7.235 7.235 0 0 1-6.883-4.803 7.24 7.24 0 0 1-.404-2.936 8.348 8.348 0 0 1 2.593-5.464 8.347 8.347 0 0 1 5.604-2.275h53.455a7.24 7.24 0 0 1 7.288 7.739 8.348 8.348 0 0 1-2.593 5.464 8.347 8.347 0 0 1-5.604 2.275ZM169.225 179.55h-53.463a7.239 7.239 0 0 1-6.881-4.809 7.256 7.256 0 0 1-.406-2.936 8.338 8.338 0 0 1 8.197-7.72h53.462a7.235 7.235 0 0 1 6.883 4.803 7.24 7.24 0 0 1 .404 2.936 8.348 8.348 0 0 1-8.196 7.726ZM168.001 200.345h-53.455a7.239 7.239 0 0 1-6.884-4.803 7.25 7.25 0 0 1-.404-2.935 8.345 8.345 0 0 1 8.197-7.745h53.456a7.237 7.237 0 0 1 5.336 2.279 7.251 7.251 0 0 1 1.951 5.466 8.339 8.339 0 0 1-8.197 7.738Z"
        fill="#42495F"
      />
      <path
        d="M121.138 153.784c1.628-1.581 1.74-4.105.251-5.638-1.488-1.533-4.015-1.495-5.642.086-1.628 1.58-1.74 4.104-.251 5.637 1.489 1.533 4.015 1.495 5.642-.085ZM117.97 168.036l.527 1.988a.517.517 0 0 0 .501.376h2.089a.538.538 0 0 1 .269 1.004l-1.762 1.254a.575.575 0 0 0-.238.627l.527 1.988a.58.58 0 0 1-.553.729.58.58 0 0 1-.332-.102l-1.618-1.254a.544.544 0 0 0-.627 0l-1.762 1.254a.526.526 0 0 1-.809-.627l.759-1.988a.523.523 0 0 0-.163-.627l-1.618-1.254a.574.574 0 0 1-.161-.636.569.569 0 0 1 .544-.368h2.088a.584.584 0 0 0 .545-.376l.734-1.988a.553.553 0 0 1 1.06 0ZM119.419 190.5l-.333 5.581a.93.93 0 0 1-.922.865h-4.885a.802.802 0 0 1-.722-.558.804.804 0 0 1-.037-.319l.408-6.949a.932.932 0 0 1 .916-.872h3.511l2.064 2.252Z"
        fill="#FFD04E"
      />
      <path
        d="m117.324 188.261 2.114 2.239h-1.374a.807.807 0 0 1-.77-.537.799.799 0 0 1-.045-.329l.075-1.373Zm-.558-1.536-.088 1.498-.081 1.374a1.496 1.496 0 0 0 .395 1.097 1.452 1.452 0 0 0 1.072.458h2.872l-1.028-1.097-2.107-2.239-1.035-1.091Z"
        fill="#42495F"
      />
      <path
        opacity={0.2}
        d="M168.648 150.188h-40.004a1.177 1.177 0 0 1-1.186-1.254 1.364 1.364 0 0 1 1.336-1.254h39.998a1.19 1.19 0 0 1 .866.369 1.184 1.184 0 0 1 .319.885 1.354 1.354 0 0 1-1.329 1.254ZM161.066 154.352h-32.661a1.177 1.177 0 0 1-1.121-.778 1.168 1.168 0 0 1-.07-.476 1.357 1.357 0 0 1 1.335-1.254h32.661a1.173 1.173 0 0 1 1.192 1.254 1.364 1.364 0 0 1-1.336 1.254ZM167.331 172.627h-11.038a1.356 1.356 0 0 0-1.335 1.254 1.166 1.166 0 0 0 .319.885 1.182 1.182 0 0 0 .866.369h11.037a1.351 1.351 0 0 0 1.336-1.254 1.19 1.19 0 0 0-.32-.885 1.178 1.178 0 0 0-.865-.369ZM149.84 172.627h-22.507a1.354 1.354 0 0 0-1.336 1.254 1.173 1.173 0 0 0 1.185 1.254h22.514a1.357 1.357 0 0 0 1.336-1.254 1.172 1.172 0 0 0-.322-.887 1.177 1.177 0 0 0-.87-.367ZM160.238 168.475h-32.667a1.356 1.356 0 0 0-1.336 1.255 1.182 1.182 0 0 0 1.186 1.254h32.66a1.36 1.36 0 0 0 1.336-1.254 1.17 1.17 0 0 0-.71-1.158 1.183 1.183 0 0 0-.469-.097ZM166.108 193.422H126.11a1.368 1.368 0 0 0-1.336 1.254c-.01.162.013.325.068.478a1.173 1.173 0 0 0 1.124.777h39.998a1.358 1.358 0 0 0 1.329-1.255 1.182 1.182 0 0 0-1.185-1.254ZM159.015 189.264h-32.66a1.365 1.365 0 0 0-1.336 1.255 1.182 1.182 0 0 0 1.185 1.254h32.667a1.353 1.353 0 0 0 1.329-1.254 1.166 1.166 0 0 0-.319-.885 1.173 1.173 0 0 0-.866-.37Z"
        fill="#FFD04E"
      />
    </svg>
  );
};
export default SvgPhoneProfileIllustration;
