import * as React from 'react';
import { SVGProps } from 'react';

const SvgCheckmarkSelectedIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m10 15.172 9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414 4.95 4.95Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgCheckmarkSelectedIcon;
