import * as React from 'react';
import { SVGProps } from 'react';

const SvgJivoMobile = (props: SVGProps) => {
  return (
    <svg
      width={49}
      height={49}
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M24.5 49C38.031 49 49 38.031 49 24.5S38.031 0 24.5 0 0 10.969 0 24.5 10.969 49 24.5 49Z"
          fill="#FFD04E"
        />
        <path
          d="M24.537 10.236c-9.264 0-16.773 5.904-16.773 13.183 0 3.446 1.684 6.589 4.442 8.937-.105 2.29-.58 5.367-2.257 6.954 3.204 0 6.484-2.11 8.43-3.63 1.907.595 3.984.921 6.158.921 9.263 0 16.773-5.903 16.773-13.182 0-7.28-7.51-13.183-16.773-13.183Z"
          fill="#292932"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.525 20.014a1.32 1.32 0 0 0-1.314-1.324 1.32 1.32 0 0 0-1.314 1.324c0 .284.089.546.24.761l-1.785 1.798a1.3 1.3 0 0 0-.567-.227v-4.632a1.32 1.32 0 0 0 1.126-1.308 1.32 1.32 0 0 0-1.315-1.324c-.726 0-1.314.593-1.314 1.324a1.32 1.32 0 0 0 1.056 1.297v4.654a1.322 1.322 0 0 0-.816 2.058l-1.8 1.813a1.298 1.298 0 0 0-.565-.238v-4.67a1.32 1.32 0 0 0 1.105-1.306 1.32 1.32 0 0 0-1.314-1.324 1.32 1.32 0 0 0-1.314 1.324 1.32 1.32 0 0 0 1.075 1.3v4.68a1.32 1.32 0 0 0-1.075 1.301 1.32 1.32 0 0 0 1.314 1.324 1.32 1.32 0 0 0 1.314-1.324c0-.275-.084-.531-.227-.744l1.803-1.816c.148.105.316.18.5.217v4.654a1.322 1.322 0 0 0-1.056 1.298c0 .731.588 1.324 1.314 1.324a1.32 1.32 0 0 0 1.315-1.324 1.32 1.32 0 0 0-1.126-1.308v-4.633a1.32 1.32 0 0 0 .884-2.071l1.784-1.797a1.3 1.3 0 0 0 .534.222v4.675a1.32 1.32 0 0 0-1.09 1.303 1.32 1.32 0 0 0 1.314 1.324 1.32 1.32 0 0 0 1.314-1.324 1.32 1.32 0 0 0-1.09-1.303v-4.675a1.32 1.32 0 0 0 1.09-1.303Zm2.309 2.652a1.32 1.32 0 1 0-.073 2.638 1.32 1.32 0 0 0 .073-2.638Zm-14.508 0a1.32 1.32 0 1 0-.073 2.638 1.32 1.32 0 0 0 .073-2.638Z"
          fill="#FFD04E"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h49v49H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgJivoMobile;
