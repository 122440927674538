import * as React from 'react';
import { SVGProps } from 'react';

const SvgLottery = (props: SVGProps) => {
  return (
    <svg
      width={142}
      height={75}
      viewBox="0 0 142 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <g opacity={0.34} clipPath="url(#b)">
          <path
            d="M141.458 122.073 76.846 10.163l-46.35 26.759L95.11 148.833l46.349-26.76Z"
            fill="url(#c)"
          />
          <path
            d="M96.227 144.456 34.77 38.008l40.88-23.603 61.459 106.449-40.88 23.602ZM36.503 38.606 96.73 142.922l38.798-22.399L75.301 16.206l-38.798 22.4Z"
            fill="#8C6E20"
          />
          <path
            d="m99.647 71.605.426 2.241 3.107.871-3.533-3.112Z"
            fill="#FFCB45"
          />
          <path
            d="m99.647 71.605 3.533 3.112-1.148-2.89-2.385-.222Z"
            fill="#FFCB45"
          />
          <path
            d="m99.648 71.605-4.64-.519 2.687 1.647 1.953-1.128Z"
            fill="#FFCB45"
          />
          <path
            d="m99.647 71.606-1.952 1.127.082 3.15 1.87-4.277Z"
            fill="#FFCB45"
          />
          <path
            d="m99.647 71.606-1.87 4.277 2.296-2.036-.425-2.241ZM97.843 69.983l-2.835 1.103 4.64.52-1.805-1.623Z"
            fill="#FFCB45"
          />
          <path
            d="m99.648 71.606 2.384.221 1.652-2.552-4.036 2.33ZM99.648 71.605l-1.006-4.749-.799 3.126 1.805 1.623Z"
            fill="#FFCB45"
          />
          <path
            d="m103.683 69.275-3.035.154-1 2.176 4.035-2.33ZM99.647 71.605l1.001-2.176-2.006-2.573 1.005 4.749ZM93.583 63.206l.378 1.558 2.148.714-2.526-2.272Z"
            fill="#FFCB45"
          />
          <path
            d="m93.583 63.206 2.526 2.271-.763-2.224-1.763-.047ZM93.583 63.205l-3.42-.512 1.988 1.339 1.432-.827Z"
            fill="#FFCB45"
          />
          <path
            d="m93.583 63.206-1.432.827.089 2.258 1.343-3.085Z"
            fill="#FFCB45"
          />
          <path
            d="m93.583 63.206-1.343 3.085 1.722-1.527-.379-1.558ZM92.346 61.965l-2.184.728 3.42.512-1.236-1.24ZM93.583 63.205l1.764.048 1.23-1.776-2.864 1.653-.13.075Z"
            fill="#FFCB45"
          />
          <path
            d="m93.583 63.206-.78-3.457-.457 2.217 1.237 1.24ZM96.578 61.477l-2.231.044-.634 1.61 2.865-1.654Z"
            fill="#FFCB45"
          />
          <path
            d="m93.583 63.206.13-.075.634-1.61-1.545-1.772.781 3.457Z"
            fill="#FFCB45"
          />
          <path
            d="m74.308 59.985.8 1.387-3.258 2.805c-.733.609-1.33 1.138-2.063 1.746.893-.33 1.65-.583 2.543-.913l4.22-1.142.64 1.11-3.099 3.082c-.732.608-1.33 1.138-1.983 1.885.893-.331 1.65-.584 2.544-.914l4.059-1.42.56.971-8.118 2.838-.96-1.664 3.233-3.16c.462-.452.924-.904 1.466-1.217-.622.174-1.244.349-1.786.661l-4.354 1.22-.96-1.663 6.516-5.612ZM79.912 69.691l.72 1.248-7.317 4.225-.72-1.248 7.317-4.225ZM81.752 72.88l.801 1.388-2.704 4.704-1.09 1.553c.677-.39 1.546-1.077 2.359-1.546l3.116-1.8.72 1.248-7.316 4.225-.801-1.387 2.704-4.704 1.09-1.554c-.813.47-1.546 1.078-2.359 1.547l-3.116 1.8-.72-1.249 7.316-4.224ZM68.656 58.184 52.97 31.014l-1.196.691 15.686 27.17 1.196-.691ZM70.45 57.147l-11.154-19.32-.598.346 11.154 19.32.599-.346ZM66.263 59.564 55.11 40.244l-.598.346 11.154 19.32.598-.346Z"
            fill="#fff"
          />
        </g>
        <g clipPath="url(#d)">
          <path
            d="M142 95.375 50.625 4 12.781 41.844l91.375 91.375L142 95.375Z"
            fill="url(#e)"
          />
          <path
            d="M104.104 128.702 17.189 41.787 50.57 8.408l86.914 86.915-33.379 33.379ZM19.019 41.916l85.174 85.174 31.678-31.678-85.174-85.174-31.678 31.678Z"
            fill="#8C6E20"
          />
          <path
            d="m88.552 57.448.992 2.054 3.226.037-4.218-2.091Z"
            fill="#1F2026"
          />
          <path
            d="m88.553 57.447 4.217 2.092-1.856-2.495-2.361.403ZM88.553 57.447l-4.616.7 3.021.895 1.595-1.595Z"
            fill="#1F2026"
          />
          <path
            d="m88.552 57.447-1.594 1.595.895 3.02.7-4.615Z"
            fill="#1F2026"
          />
          <path
            d="m88.552 57.447-.7 4.616 1.692-2.561-.992-2.055Z"
            fill="#1F2026"
          />
          <path
            d="m86.39 56.347-2.453 1.8 4.616-.7-2.163-1.1ZM88.552 57.448l2.361-.403.935-2.893-3.296 3.296Z"
            fill="#1F2026"
          />
          <path
            d="m88.552 57.447-2.2-4.326.037 3.226 2.163 1.1Z"
            fill="#1F2026"
          />
          <path
            d="m91.848 54.152-2.893.934-.403 2.361 3.296-3.295Z"
            fill="#1F2026"
          />
          <path
            d="m88.552 57.447.403-2.36-2.603-1.966 2.2 4.326ZM95.097 65.48l.877 1.515 2.26.133-3.137-1.648Z"
            fill="#1F2026"
          />
          <path
            d="m95.097 65.479 3.137 1.65-1.313-1.952-1.824.302ZM95.097 65.48l-3.328.499 2.158.67 1.17-1.17Z"
            fill="#1F2026"
          />
          <path d="m95.097 65.48-1.17 1.169.67 2.158.5-3.328Z" fill="#1F2026" />
          <path
            d="m95.097 65.48-.5 3.327 1.377-1.812-.877-1.516Z"
            fill="#1F2026"
          />
          <path
            d="m93.581 64.602-1.812 1.377 3.328-.5-1.516-.877ZM95.097 65.48l1.825-.302.62-2.144-2.339 2.339-.106.106Z"
            fill="#1F2026"
          />
          <path
            d="m95.097 65.48-1.54-3.029.024 2.151 1.516.878ZM97.542 63.034l-2.144.62-.195 1.719 2.339-2.34Z"
            fill="#1F2026"
          />
          <path
            d="m95.097 65.48.106-.107.195-1.718-1.841-1.204 1.54 3.028ZM80.52 50.903l.77 1.407 2.259.134-3.029-1.54Z"
            fill="#1F2026"
          />
          <path
            d="m80.52 50.904 3.029 1.54-1.313-1.95-1.716.41ZM80.52 50.904l-3.436.39 2.267.78 1.169-1.17Z"
            fill="#1F2026"
          />
          <path d="m80.52 50.903-1.169 1.17.67 2.158.5-3.328Z" fill="#1F2026" />
          <path
            d="m80.52 50.903-.499 3.328 1.268-1.921-.769-1.407Z"
            fill="#1F2026"
          />
          <path
            d="m79.004 50.026-1.92 1.268 3.436-.39-1.516-.878Z"
            fill="#1F2026"
          />
          <path
            d="m80.52 50.904 1.716-.41.73-2.035-2.34 2.339-.106.106ZM80.52 50.904l-1.649-3.137.134 2.26 1.515.877Z"
            fill="#1F2026"
          />
          <path
            d="m82.965 48.459-2.143.62-.196 1.718 2.34-2.338Z"
            fill="#1F2026"
          />
          <path
            d="m80.52 50.903.107-.106.195-1.718-1.95-1.313 1.649 3.137ZM61.069 52.783l1.132 1.132-2.421 3.554c-.55.777-.99 1.443-1.541 2.22.777-.55 1.443-.99 2.22-1.541l3.78-2.195.906.906-2.195 3.78c-.55.777-.99 1.443-1.428 2.333.777-.55 1.443-.99 2.22-1.54l3.554-2.422.792.793-7.106 4.842-1.359-1.359 2.305-3.89c.33-.556.659-1.112 1.102-1.554-.556.33-1.112.659-1.555 1.101l-3.89 2.305-1.358-1.358 4.842-7.107ZM68.994 60.708l1.019 1.019-5.975 5.974-1.019-1.019 5.975-5.974ZM71.598 63.313l1.132 1.132-1.395 5.243-.65 1.784c.553-.554 1.214-1.441 1.878-2.105l2.544-2.545 1.02 1.02-5.975 5.974-1.132-1.133 1.394-5.243.651-1.784c-.664.664-1.214 1.441-1.878 2.105l-2.545 2.545-1.019-1.02 5.975-5.974ZM77.711 69.426l1.133 1.132-1.395 5.244-.65 1.783c.552-.553 1.214-1.441 1.877-2.105l2.545-2.545 1.019 1.02-5.974 5.974-1.133-1.132 1.395-5.244.65-1.783c-.663.664-1.214 1.44-1.877 2.105l-2.545 2.544-1.019-1.019 5.974-5.974Z"
            fill="#1F2026"
          />
          <path
            d="M55.144 52.505 32.96 30.32l-.976.977 22.183 22.184.977-.977ZM56.609 51.04 40.834 35.265l-.488.488L56.12 51.528l.488-.488ZM53.19 54.459 37.416 38.684l-.489.488 15.775 15.775.489-.488Z"
            fill="#8C6E20"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h142v86H0z" />
        </clipPath>
        <clipPath id="b">
          <path
            fill="#fff"
            transform="rotate(60 29.622 71.631)"
            d="M0 0h129.224v53.21H0z"
          />
        </clipPath>
        <clipPath id="d">
          <path
            fill="#fff"
            transform="rotate(45 20.484 63.11)"
            d="M0 0h129.224v53.21H0z"
          />
        </clipPath>
        <linearGradient
          id="c"
          x1={109.152}
          y1={66.118}
          x2={62.803}
          y2={92.877}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={96.312}
          y1={49.687}
          x2={58.469}
          y2={87.531}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgLottery;
