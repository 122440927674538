import * as React from 'react';
import { SVGProps } from 'react';

const SvgExchangeArrowIcon = (props: SVGProps) => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="#F8FAFC"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14 25.666C7.557 25.666 2.334 20.443 2.334 14 2.334 7.556 7.557 2.333 14 2.333c6.444 0 11.667 5.223 11.667 11.667 0 6.443-5.223 11.666-11.667 11.666Zm0-2.333a9.333 9.333 0 1 0 0-18.666 9.333 9.333 0 0 0 0 18.666Zm-5.833-8.167h10.5V17.5H14V21l-5.833-5.834ZM14 10.5V7l5.834 5.833h-10.5V10.5H14Z" />
    </svg>
  );
};
export default SvgExchangeArrowIcon;
