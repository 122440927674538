import * as React from 'react';
import { SVGProps } from 'react';

const SvgWarningIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#F87171"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16.001A8 8 0 0 0 12 20Zm-1-5h2v2h-2v-2Zm0-8h2v6h-2V7Z" />
    </svg>
  );
};
export default SvgWarningIcon;
