import * as React from 'react';
import { SVGProps } from 'react';

const SvgArrowDropdownIcon = (props: SVGProps) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 12.5 6.464 8.966l1.179-1.178L10 10.143l2.356-2.357 1.18 1.178L10 12.5Z" />
    </svg>
  );
};
export default SvgArrowDropdownIcon;
