import * as React from 'react';
import { SVGProps } from 'react';

const SvgFactor1X = (props: SVGProps) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={16}
        cy={16}
        r={15}
        fill="#FF9838"
        stroke="#5B5B64"
        strokeWidth={2}
      />
      <path
        d="m11.487 16.144 1.664-2.56h2.464l-2.88 4.048c.64.8 1.222 1.579 1.744 2.336.523.757.94 1.435 1.248 2.032h-2.56c-.085-.16-.192-.357-.32-.592a11.183 11.183 0 0 0-.432-.752 12.3 12.3 0 0 0-.512-.784 8.38 8.38 0 0 0-.512-.72c-.16.203-.325.427-.496.672-.16.245-.314.496-.464.752l-.448.736c-.138.245-.26.475-.368.688H7.183c.128-.267.304-.581.528-.944a29.074 29.074 0 0 1 1.552-2.288c.288-.395.571-.763.848-1.104-.48-.672-.96-1.35-1.44-2.032-.48-.693-.954-1.376-1.424-2.048h2.576l1.664 2.56Zm5.516-2.928c.31-.128.63-.272.96-.432.341-.17.672-.352.992-.544.32-.203.624-.41.912-.624.299-.224.565-.459.8-.704h1.664V22h-2.384v-7.984a7.8 7.8 0 0 1-1.072.592c-.395.17-.779.32-1.152.448l-.72-1.84Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgFactor1X;
