import * as React from 'react';
import { SVGProps } from 'react';

const SvgLoader = (props: SVGProps) => {
  return (
    <svg
      className="loader_svg__loader-img"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: 'auto',
        display: 'block',
      }}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      {...props}
    >
      <circle
        cx={50}
        cy={50}
        fill="none"
        stroke="#FFD04E"
        strokeWidth={10}
        r={30}
        strokeDasharray="45 15"
        transform="rotate(146.073 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        />
      </circle>
    </svg>
  );
};
export default SvgLoader;
