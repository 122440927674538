import * as React from 'react';
import { SVGProps } from 'react';

const SvgCaretDownIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m20.031 9.53-7.5 7.5a.749.749 0 0 1-1.061 0l-7.5-7.5A.75.75 0 0 1 5.03 8.47l6.97 6.97 6.969-6.97a.75.75 0 0 1 1.061 1.06Z"
        fill="#000"
      />
    </svg>
  );
};
export default SvgCaretDownIcon;
