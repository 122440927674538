import * as React from 'react';
import { SVGProps } from 'react';

const SvgCheckmarkCircleIcon = (props: SVGProps) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 18.333a8.333 8.333 0 1 1 0-16.666 8.333 8.333 0 1 1 0 16.666Zm0-1.666a6.667 6.667 0 1 0 0-13.334 6.667 6.667 0 0 0 0 13.334Zm-.831-3.334L5.633 9.797 6.811 8.62l2.358 2.358 4.713-4.715 1.18 1.179-5.893 5.892Z"
        fill="#22C55E"
      />
    </svg>
  );
};
export default SvgCheckmarkCircleIcon;
