import * as React from 'react';
import { SVGProps } from 'react';

const SvgBronzeStatus = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.993 68C52.715 68 68 52.728 68 34.007 68 15.285 52.728 0 33.993 0 15.272 0 0 15.272 0 34.007 0 52.728 15.272 68 33.993 68Z"
        fill="#FFC250"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.947 32.067c.04.642.053 1.284.053 1.94C68 52.781 52.782 68 33.993 68 15.218 68 0 52.782 0 34.006c0-.655.027-1.297.053-1.939 1.003 17.88 15.82 32.068 33.94 32.068 18.134 0 32.95-14.188 33.953-32.068Z"
        fill="#EBB044"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.993 59.36c13.8 0 25.06-11.26 25.06-25.06 0-13.801-11.246-25.06-25.06-25.06-13.8 0-25.06 11.259-25.06 25.06 0 13.8 11.26 25.06 25.06 25.06Z"
        fill="#FF9838"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.037 26.637c0-1.459-1.208-2.641-2.698-2.641s-2.698 1.182-2.698 2.64c0 .566.183 1.09.493 1.519l-3.664 3.586a2.717 2.717 0 0 0-1.164-.452v-9.24c1.306-.185 2.31-1.282 2.31-2.61 0-1.459-1.208-2.641-2.698-2.641s-2.698 1.182-2.698 2.64c0 1.28.931 2.348 2.167 2.59v9.282c-1.236.242-2.167 1.309-2.167 2.59 0 .565.183 1.088.493 1.517l-3.694 3.615a2.706 2.706 0 0 0-1.162-.475v-9.316c1.286-.201 2.27-1.289 2.27-2.604 0-1.459-1.208-2.641-2.698-2.641s-2.698 1.182-2.698 2.64c0 1.295.951 2.37 2.207 2.596v9.335c-1.256.226-2.207 1.3-2.207 2.594 0 1.459 1.207 2.641 2.698 2.641 1.49 0 2.698-1.182 2.698-2.64a2.59 2.59 0 0 0-.467-1.485l3.702-3.623c.302.21.649.36 1.025.434v9.283c-1.236.241-2.167 1.309-2.167 2.589 0 1.458 1.208 2.64 2.698 2.64s2.698-1.182 2.698-2.64c0-1.33-1.004-2.425-2.31-2.61v-9.24c1.306-.186 2.31-1.282 2.31-2.61a2.58 2.58 0 0 0-.496-1.523l3.663-3.585c.32.222.693.376 1.096.444v9.326c-1.27.214-2.238 1.295-2.238 2.6 0 1.458 1.208 2.64 2.698 2.64s2.698-1.182 2.698-2.64c0-1.305-.968-2.386-2.238-2.6v-9.326c1.27-.214 2.238-1.295 2.238-2.6Zm5.067 4.202c-1.565 0-2.834 1.244-2.834 2.778 0 1.535 1.27 2.779 2.834 2.779 1.565 0 2.834-1.244 2.834-2.779 0-1.534-1.269-2.778-2.834-2.778Zm-30.226 0c-1.566 0-2.834 1.244-2.834 2.778 0 1.535 1.268 2.779 2.834 2.779 1.565 0 2.833-1.244 2.833-2.779 0-1.534-1.268-2.778-2.833-2.778Z"
        fill="#FFF3BF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.986 32.067c.054.642.08 1.284.08 1.94 0 13.84-11.219 25.06-25.06 25.06-13.84 0-25.06-11.22-25.06-25.06 0-.656.027-1.298.08-1.94.99 12.945 11.795 23.122 24.98 23.122 13.186.013 24.004-10.177 24.98-23.122Z"
        fill="#FF8709"
      />
    </svg>
  );
};
export default SvgBronzeStatus;
