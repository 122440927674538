import * as React from 'react';
import { SVGProps } from 'react';

const SvgBonusHistoryIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.171 8.007A10.002 10.002 0 0 0 4.447 5.446L2 3v6h6L5.865 6.865A8 8 0 0 1 18.929 8H21c.058 0 .115.002.171.007ZM18.001 20H10a1.998 1.998 0 0 1-1.663-.888A8 8 0 0 1 4 12H2c0 5.523 4.477 10 10 10 2.252 0 4.33-.744 6.001-2Z"
        fill="#F8FAFC"
      />
      <path
        d="m17.32 11.5.78.75-4.42 4.25-.78-.75 4.42-4.25ZM10.3 9h10.4c.722 0 1.3.556 1.3 1.25v2.5c-.345 0-.675.132-.92.366-.243.235-.38.553-.38.884 0 .332.137.65.38.884.245.234.575.366.92.366v2.5c0 .694-.578 1.25-1.3 1.25H10.3c-.345 0-.675-.132-.92-.366A1.226 1.226 0 0 1 9 17.75v-2.5c.722 0 1.3-.556 1.3-1.25 0-.332-.137-.65-.38-.884A1.327 1.327 0 0 0 9 12.75v-2.5c0-.332.137-.65.38-.884.245-.234.575-.366.92-.366Zm0 1.25v1.588c.395.219.723.534.951.913a2.423 2.423 0 0 1 0 2.497c-.228.38-.556.696-.951.915v1.587h10.4v-1.587a2.549 2.549 0 0 1-.951-.914A2.423 2.423 0 0 1 19.4 14c0-.438.12-.87.348-1.249.228-.38.556-.694.951-.913V10.25H10.3Zm3.575 1.25c.54 0 .975.419.975.938 0 .518-.435.937-.975.937s-.975-.419-.975-.938c0-.518.435-.937.975-.937Zm3.25 3.125c.54 0 .975.419.975.938 0 .518-.436.937-.975.937-.54 0-.975-.419-.975-.938 0-.518.436-.937.975-.937Z"
        fill="#F8FAFC"
      />
    </svg>
  );
};
export default SvgBonusHistoryIcon;
