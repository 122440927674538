import * as React from 'react';
import { SVGProps } from 'react';

const SvgRecentExchangesIcon = (props: SVGProps) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="svg-fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12c0-5.523-4.477-10-10-10a9.98 9.98 0 0 0-7.553 3.446L2 3v6h6L5.865 6.865a8 8 0 0 1 14.015 3.753c.797.36 1.515.866 2.12 1.482V12Zm-9.9 10a7.015 7.015 0 0 1-1.482-2.121h-.004A8 8 0 0 1 4 12H2c0 5.523 4.477 10 10 10h.1Z"
        fill="#F8FAFC"
      />
      <path
        className="svg-fill"
        d="M17 23a6 6 0 1 1 0-12 6 6 0 0 1 0 12Zm0-1.2a4.8 4.8 0 1 0 0-9.6 4.8 4.8 0 0 0 0 9.6Zm-3-4.2h5.4v1.2H17v1.8l-3-3Zm3-2.4v-1.8l3 3h-5.4v-1.2H17Z"
        fill="#F8FAFC"
      />
    </svg>
  );
};
export default SvgRecentExchangesIcon;
